import FriendlyWebSocket from '@utils/FriendlySocket'
import { getRandomEmoji } from '@utils/people'
import { useStore } from '@stores/3d'

const room = 'rara'
const socket = new FriendlyWebSocket({
  url: 'wss://rara-donate.herokuapp.com/r/rara',
})

const emoji = getRandomEmoji().name

const join = () => {
  socket.send({ type: 'join', room })
}

const like = () => {
  socket.send({ type: 'like', room })
}

const update = () => {
  socket.send({ type: 'update', room, data: { emoji } })
}

const sendMessage = (message: any) => {
  socket.send({
    type: 'chat',
    room,
    chatmessage: message.content,
    chatname: message.name,
  })
}

const updateAdmin = (
  pass: string,
  values: { [key: string]: string | boolean }
) => {
  socket.send({ type: 'admin', pass, room, settings: values })
}

const handleMessage = (data) => {
  if (!data || !data.type) {
    console.error('Unknown message')
    return
  }
  switch (data.type) {
    case 'liked':
      useStore.getState().actions.addHeart()
      return
    case 'update':
      const {
        settings: {
          color,
          live,
          streamURL,
          testStreamURL,
          rerunStreamURL,
          rerun,
          socialLinks,
          radioText,
          clubText,
          clubSchedule,
          showLinksOffline,
          showChat,
        },
        clients,
      } = data.data

      useStore.setState({
        color,
        live,
        streamURL:
          streamURL && streamURL !== ''
            ? streamURL
            : 'https://stream.radioradio.radio/radioradio/donate/playlist.m3u8',
        testStreamURL:
          testStreamURL && testStreamURL !== ''
            ? testStreamURL
            : 'https://stream.radioradio.radio/radioradio/test/playlist.m3u8',
        rerunStreamURL:
          rerunStreamURL && rerunStreamURL !== ''
            ? rerunStreamURL
            : 'https://stream.radioradio.radio/radioradio/offline/playlist.m3u8',
        socialLinks: socialLinks ? socialLinks : [],
        clubSchedule: clubSchedule ? clubSchedule : [],
        clubText,
        radioText,
        rerun,
        streamcount: clients.length,
        clients,
        showLinksOffline: !!showLinksOffline,
        showChat: !!showChat,
      })

      return
    case 'chatmessage':
      useStore.getState().actions.addMessage({
        content: data.data.chatmessage,
        name: data.data.chatname,
      })
      return
    default:
      console.error('Unknown message')
      return
  }
}

const initSocket = () => {
  if (socket?.connected) return

  socket.on('open', () => {
    join()
    update()
  })
  socket.on('message', (data) => {
    handleMessage(JSON.parse(data))
  })

  socket.connect()
}

const visibilityCheck = () => {
  let hidden = 'hidden'
  let visibilityChange = 'visibilitychange'

  if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  function handleVisibilityChange() {
    if (document[hidden]) {
      useStore.setState({ listenForHearts: false })
    } else {
      useStore.setState({ listenForHearts: true })
    }
  }

  document.addEventListener(visibilityChange, handleVisibilityChange, false)
}
if (typeof window !== 'undefined') visibilityCheck()

export { initSocket, join, update, like, updateAdmin, sendMessage }
